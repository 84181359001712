"use client";

import { Row, Col } from 'antd/lib';

import Image from "next/image";

import './Roadmap.styles.scss';

export default function Roadmap() {

  
  return (
    <div className="roadmap-container">
      <Row className="roadmap-row">
         <Col
            xs={{ span: 14 }}
            md={{ span: 14 }}
            sm={{ span: 14 }}
            lg={{ span: 14 }}
            className="ant-col-title"  
         > 
         </Col>
       </Row>
    </div>
  );
}