"use client";

import { Row, Col, Card } from "antd/lib";
import Image from "next/image";
import CheckedIcon from "../../../public/home/checked-icon.svg";
import CheckedRoundIcon from "../../../public/home/checked-round-icon.svg";
import CheckedRoundIconTest from "../../../public/home/checked-icon-test.svg";

import "./Body.styles.scss";

const { Meta } = Card;

export default function Body() {
  return (
    <div className="body-container">
      <Row className="body-row">
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
        >
          <h1 className="body-title">
            <div className="body-title-colour">Directly invest and own a part </div> of a renewables farm
          </h1>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
          className="body-banner"
        >
          <Col
            xs={{ span: 6 }}
            md={{ span: 6 }}
            sm={{ span: 6 }}
            lg={{ span: 6 }}
            className="body-banner-checked"
          >
            <CheckedIcon className="body-checked-icon" />
            <h2 className="body-checked-text">Easy access</h2>
          </Col> 
          <Col
            xs={{ span: 6 }}
            md={{ span: 6 }}
            sm={{ span: 6 }}
            lg={{ span: 6 }}
            className="body-banner-checked"
          >
            <CheckedIcon className="body-checked-icon" />
            <h2 className="body-checked-text">Automated payments</h2>
          </Col> 
          <Col
            xs={{ span: 6 }}
            md={{ span: 6 }}
            sm={{ span: 6 }}
            lg={{ span: 6 }}
            className="body-banner-checked"
          >
            <CheckedIcon className="body-checked-icon" />
            <h2 className="body-checked-text">Real asset ownership</h2>
          </Col> 
          <Col
            xs={{ span: 6 }}
            md={{ span: 6 }}
            sm={{ span: 6 }}
            lg={{ span: 6 }}
            className="body-banner-checked"
          >
            <CheckedIcon className="body-checked-icon" />
            <h2 className="body-checked-text">Monthly dividends</h2>
          </Col> 
        </Col>
      </Row>

      {/* BODY ROW FOR INTRO CARDS */}

      <Row className="body-row-card-intro">
        <Col
          xs={{ span: 8 }}
          md={{ span: 8 }}
          sm={{ span: 8 }}
          lg={{ span: 8 }}
        >
          <Card
            className="body-card"
            cover={
               <Image
                  src="/home/body-solar-panels.png"
                  width={500}
                  height={500}
                  alt="illustration solar panels"
                  className="body-solar-panels-card"
               />
            }
          >
            <Meta title="Own your renewables farm" description="Formalize direct ownership of the solar farm through your unique digital ID and real equity of the invested renewables farm." />
          </Card>
        </Col>
        <Col
          xs={{ span: 8 }}
          md={{ span: 8 }}
          sm={{ span: 8 }}
          lg={{ span: 8 }}
        >
          <Card
            className="body-card"
            cover={
               <Image
                  src="/home/body-energy.png"
                  width={500}
                  height={500}
                  alt="illustration clean energy"
                  className="body-solar-panels-card"
               />
            }
          >
            <Meta title="Sell your energy" description="Establish passive revenue by selling clean energy generated from your farm to fixed long-term buyers." />
          </Card>
        </Col>
        <Col
            xs={{ span: 8 }}
            md={{ span: 8 }}
            sm={{ span: 8 }}
            lg={{ span: 8 }}
        >
          <Card
            className="body-card"
            cover={
              <Image
                src="/home/body-distribution.png"
                width={500}
                height={500}
                alt="illustration dividends payout"
                className="body-solar-panels-card"
              />
            }
          >
            <Meta title="Collect your returns" description="Receive monthly dividends from the fixed sale of your energy and profits generated from your renewables farm." />
          </Card>
        </Col>
      </Row>

      {/* BODY ROW FOR BENEFITS CARD */}

      <Row className="body-row-card-benefits">
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
        >
          <Card
            className="body-card-benefits"
          >
            <Meta
              title="Interoperability through Energy Tokenization"
              description={
                <Row>
                  <Col
                    xs={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    lg={{ span: 8 }}
                    className="body-card-benefits-checked"
                  >
                    <CheckedRoundIcon className="body-checked-round-icon" />
                    <h2 className="body-checked-round-text">On-chain compliance and controls</h2>
                  </Col>
                  <Col
                    xs={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    lg={{ span: 8 }}
                    className="body-card-benefits-checked"
                  >
                    <CheckedRoundIcon className="body-checked-round-icon" />
                    <h2 className="body-checked-round-text">Automated payouts execution</h2>
                  </Col>
                  <Col
                    xs={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 8 }}
                    lg={{ span: 8 }}
                    className="body-card-benefits-checked"
                  >
                    <CheckedRoundIcon className="body-checked-round-icon" />
                    <h2 className="body-checked-round-text">Monthly dividends distribution</h2>
                  </Col>
                </Row>
              }
            />
              <div className="body-card-benefits-content">
                <h3 className="body-card-benefits-content-text">RWA tokenization
                empowers renewable energy projects by making them more accessible, transparent, and efficient, ultimately driving more investment into sustainable energy production.
                </h3>
              </div>
          </Card>
        </Col>
      </Row>

      {/* BODY ROW PROJECTS INTRO */}

      <Row className="body-row-project-container">
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          lg={{ span: 24 }}
        >
          <h1 className="body-row-project-title">We are focusing solely on solar and hydro power</h1>
        </Col>
        <Col
          xs={{ span: 11 }}
          md={{ span: 11 }}
          sm={{ span: 11 }}
          lg={{ span: 11 }}
        >
          <Card
            className="body-card-project"
            cover={
               <Image
                  src="/home/hydro-farm2.png"
                  width={500}
                  height={500}
                  alt="illustration solar farm"
                  className="body-card-project-img"
               />
            }
            title={
              <div className="body-card-project-text">
                <h3 className="body-card-project-title">
                  Solar Power
                </h3>
                <h3 className="body-card-project-intro">
                  Solar panel farms are a cost-effective and efficient method of generating renewable energy. Their relatively quick construction, low operational costs, and consistent energy output make them an attractive investment for generating long-term profit
                </h3>
                <div className="body-card-project-benefits">
                  <div className="body-card-project-point">
                    <CheckedRoundIconTest className="body-card-point-icon" />
                    <h4>Short Construction Time</h4>
                  </div>
                  <div className="body-card-project-point">
                    <CheckedRoundIconTest className="body-card-point-icon" />
                    <h4>Low Maintenance Costs</h4>
                  </div>
                  <div className="body-card-project-point">
                    <CheckedRoundIconTest className="body-card-point-icon" />
                    <h4>Direct Profits from Energy Sales</h4>
                  </div>
                </div>
              </div>
            }
          >
          </Card>
        </Col>
        <Col
          xs={{ span: 2 }}
          md={{ span: 2 }}
          sm={{ span: 2 }}
          lg={{ span: 2 }}
          className=""
        >
        </Col>
        <Col
          xs={{ span: 11 }}
          md={{ span: 11 }}
          sm={{ span: 11 }}
          lg={{ span: 11 }}
        >
          <Card
            className="body-card-project"
            cover={
               <Image
                  src="/home/hydro-farm2.png"
                  width={500}
                  height={500}
                  alt="illustration solar farm"
                  className="body-card-project-img"
               />
            }
            title={
              <div className="body-card-project-text">
                <h3 className="body-card-project-title">
                  Hydro Power
                </h3>
                <h3 className="body-card-project-intro">
                  Hydropower farms utilizing water turbines installed in slow water currents are another renewable energy option with unique advantages. They offer continuous, reliable power generation and long-term profitability.
                </h3>
                <div className="body-card-project-benefits">
                  <div className="body-card-project-point">
                    <CheckedRoundIcon className="body-card-point-icon" />
                    <h4>Steady Energy Output</h4>
                  </div>
                  <div className="body-card-project-point">
                    <CheckedRoundIcon className="body-card-point-icon" />
                    <h4>Continuous Energy Generation</h4>
                  </div>
                  <div className="body-card-project-point">
                    <CheckedRoundIcon className="body-card-point-icon" />
                    <h4>Low Maintenance Costs</h4>
                  </div>
                </div>
              </div>
            }
          >
          </Card>
        </Col>
      </Row>

    </div>
  );
}
