"use client";

import { Row, Col, Input, Space, Button } from 'antd/lib';

import Image from "next/image";
import SendArrowIcon from '../../../public/home/send-arrow-icon.svg';

import './Landing.styles.scss';

export default function Landing() {

  
  return (
    <div className="landing-container">
      <Row className="landing-row">
         <Col
            xs={{ span: 14 }}
            md={{ span: 14 }}
            sm={{ span: 14 }}
            lg={{ span: 14 }}
            className="ant-col-title"  
         >
            <h1 className="landing-title">
               Build and Sell Renewable Energy
            </h1>
            <h5 className="landing-subtitle">
               Giving unprecedented <div className="landing-subtitle-color"> access and ownership </div> of
            </h5>
            <h5 className="landing-subtitle">
               sustainable farms to the community 
            </h5>
            <Space direction="vertical" size="middle" className="landing-newsletter-btn">
               <Space.Compact style={{ width: '100%' }}>
                  <Input placeholder="Join our newsletter" />
                  <Button type="primary" className="landing-newsletter-send">
                     <SendArrowIcon />
                  </Button>
               </Space.Compact>
            </Space>
         </Col>
         <Col
            xs={{ span: 10 }}
            md={{ span: 10 }}
            sm={{ span: 10 }}
            lg={{ span: 10 }}
            className="ant-col-img"  
         >
            <div className="landing-illustration">
               <Image
                  src="/home/landing-illustration.png"
                  width={500}
                  height={500}
                  alt="illustration landing"
                  className="landing-illustration-img"
               />
            </div>  
         </Col>
       </Row>
    </div>
  );
}